<template>
  <div>
    <c-tab
      :inlineLabel="true"
      :dense="true"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :param.sync="popupParam"
          :disabledAppr="true"
          :workPlanDatas.sync="workPlanDatas"
          :disabledObj.sync="disabledObj"
          @disabledSet="disabledSet"
          @saveWorkPlan="saveWorkPlan"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
export default {
  name: 'work-order-plan01',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        newEquipmentCd: '',
        newTitle: '',
        newWoWorkTypeCd: '', // 작업유형 (PM)
        workPlanId: '',
        woRequestId: '',
        plantCd: null,
        woWorkPlanStepCd: '',
        copyFlag: 'N',
        firstCopy: false,
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      workPlanDatas: [],
      splitter: 5,
      tab: 'planInfo',
      editable: true,
      detailUrl: '',
      completeUrl: '',
      deleteUrl: '',
      isComplete: false,
      disabledObj: {
        type: Object,
        default: () => ({
          disabled: false,
        }),
      },
    };
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      return numHeight + 'px';
    },
    tabItems() {
      return [
        { key: uid(), name: 'planInfo', icon: 'edit', label: '오더내역', component: () => import(`${"@/pages/wod/plan/workOrderPlanDirection.vue"}`) },
        { key: uid(), name: 'team', icon: 'checklist', label: '자재 및 서비스', component: () => import(`${"@/pages/wod/plan/workOrderPlanWork.vue"}`) },
        { key: uid(), name: 'attach', icon: 'checklist', label: '첨부파일', component: () => import(`${"@/pages/wod/plan/workOrderPlanFile.vue"}`) },
      ]
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = false
    },
    stepBystep2(_param) {
      this.$emit('emitStep', {
        name: 'stepBystep',
        param: {
          stepCd: _param.stepCd,
          woWorkPlanStepCd: _param.woWorkPlanStepCd,
        }
      })
    },
    closePopup() {
      this.$emit('emitStep', {
        name: 'closePopup'
      })
    },
    saveWorkPlan(_works, _equips) {
      this.workPlanDatas = [];
      let equips = [];
      this.$_.forEach(_equips, _item => {
        equips.push(_item.equipmentCd);
      });
      let cnt = 0;
      this.$_.forEach(_works, item => {
        this.workPlanDatas.push({
          name: 'tab' + cnt,
          label: item.sortOrder + '.(' + item.workOprTypeName + ') ' + item.workPlanWorkName,
          param: {workPlanId: item.workPlanId, workPlanWorkId: item.workPlanWorkId, equips: equips},
          component: () => import(`${"@/pages/wod/plan/workOrderPlanService.vue"}`),
          key: uid(),
        })
        cnt++;
      });
    },
    disabledSet(_bool) {
      this.disabledObj.disabled = _bool;
    }
  }
};
</script>
